import Router from "next/router";
import { Provider } from "next-auth/client";
import NProgress from "nprogress";

import "nprogress/nprogress.css";
import "../styles/globals.css";

import { init } from "../lib/sentry";

Router.events.on("routeChangeStart", (url) => {
  console.log(`Loading: ${url}`);
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

init();

const App = ({ Component, pageProps, err }) => {
  return (
    <Provider session={pageProps.session}>
      <Component {...pageProps} err={err} />
    </Provider>
  );
};

export default App;
